import React from "react";
import Link from "../components/Link";
import Copyright from "../components/Copyright";
import { Container, Box, Typography } from "@mui/material";

export default function About() {
  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Exciting
        </Typography>
        <Link to="/">← Home</Link>
        <Box m={10} />
        <ul>
          <li><Typography>Founder of <Link to="https://chainify.net" target="_blank" rel="noreferrer">chainify.net</Link></Typography></li>
          <li><Link to="https://gasnow.dev" target="_blank" rel="noreferrer">gasnow.dev</Link></li>
        </ul>
        <Box m={5} />
        <Copyright />
      </Box>
    </Container>
  );
}
