import React from "react";
import { Link as MuiLink} from "@mui/material";
import { Link as GatsbyLink } from "gatsby";


const Link = React.forwardRef(
  /**
   * @param {Object} props
   * @param {string} props.to
   * @param {React.ReactNode} [props.children]
   */
  function Link(props, ref) {
    return <MuiLink component={GatsbyLink} ref={ref} {...props} />;
  }
);

export default Link;
